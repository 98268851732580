import { debug, phoneNumberParser, addressParser, citizenIdParser } from '../utils';
import { SPREADSHEET_ID } from '../config';

import { CustomerInterface } from '../models';

import { checkExistingCustomers } from '../api/check-exisitng-customers';

export const getSpreadsheetRowByRange = async ({
  from,
  to,
}: {
  from: number;
  to: number;
}) => {
  // debug(`Reading spreadsheet id '${SPREADSHEET_ID}'...`);
  debug(`Reading spreadsheet...`);

  const gapi = window.gapi;

  const sheets = await gapi.client.sheets.spreadsheets.values.get({
    spreadsheetId: SPREADSHEET_ID,
    range: `ฐานข้อมูลบัตรสมาชิกลูกค้า!B${from}:I${to}`,
    // range: `ฐานข้อมูลบัตรสมาชิกลูกค้า!B27910:K27912`,
  });
  debug(sheets.result);

  if (!sheets.result.values) throw new Error('No data from spreadsheet');

  const customerList = sheets.result.values.map<Partial<CustomerInterface>>(
    (value, index) => {
      const rawId: string | undefined = value[0];
      const name: string | undefined = value[2];
      const rawPhoneNumber = value[3];
      const rawCitizenId = value[4];
      const rawAddress = value[5];
      const parsedPhoneNumber = phoneNumberParser(rawPhoneNumber ?? '');
      const acquiredChannel: string | undefined = value[6];
      const recordedBy: string | undefined = value[7]; // Supposed to be undefined

      const initialCustomer: Partial<CustomerInterface> = {
        rowNumber: Number(from) + Number(index),
        id: { value: rawId, isValid: rawId !== '' },
        name,
        rawPhoneNumber,
        parsedPhoneNumber,
        citizenId: citizenIdParser(rawCitizenId),
        address: addressParser(rawAddress),
        acquiredChannel,
        recordedBy,
        isValid: true,
        isChecked: true,
      };

      if (
        !initialCustomer.id?.isValid ||
        !name ||
        !parsedPhoneNumber ||
        !initialCustomer.citizenId?.isValid ||
        !acquiredChannel ||
        recordedBy
        // Object.values(initialCustomer.address!)
        //   .map((each) => each.isValid)
        //   .includes(false)
      )
        return { ...initialCustomer, isValid: false, isChecked: false };

      return initialCustomer;
    },
  );

  const existedCustomers = await checkExistingCustomers(
    customerList
      .filter((customer) => customer.isValid)
      .map((customer) => customer.id?.value ?? ''),
  );
  debug(`existedCustomers: `);
  debug(existedCustomers);

  return customerList.map((customer) =>
    customer.id && existedCustomers.includes(customer.id.value ?? '')
      ? {
          ...customer,
          id: { ...customer.id, isValid: false },
          isValid: false,
          isChecked: false,
        }
      : { ...customer },
  );
};
