import { AddressInterface, ValidValuePair } from '../models';

const validateMatchResult = ({
  originalText,
  matchResult,
}: {
  originalText: string;
  matchResult: RegExpMatchArray | null;
}): [ValidValuePair, string] => {
  if (!matchResult) return [{ isValid: false, value: '' }, originalText];

  const targetText = matchResult[0];

  const theRestText = originalText.substring(0, matchResult.index ?? 0 - 1).trim();

  return [{ isValid: true, value: targetText }, theRestText];
};

export const addressParser = (raw: string | undefined): AddressInterface => {
  if (!raw || raw === '')
    return {
      raw: { isValid: false, value: '' },
      general: { isValid: false, value: '' },
      district: { isValid: false, value: '' },
      province: { isValid: false, value: '' },
      zipCode: { isValid: false, value: '' },
    };

  const [zipCode, withoutZip] = validateMatchResult({
    originalText: raw,
    matchResult: raw.match(/[0-9]{5}$/),
  });

  const [province, withoutProvince] = validateMatchResult({
    originalText: withoutZip,
    matchResult: withoutZip.match(/จังหวัด[\S]+$/),
  });

  const [district, withoutdistrict] = validateMatchResult({
    originalText: withoutProvince,
    matchResult: withoutProvince.match(/[เขต|อำเภอ][\S]+$/),
  });

  const generalMatch = withoutdistrict.match(/.+(?=เขต|อำเภอ|อ\.)/);

  const general: ValidValuePair = {
    isValid: true,
    value: generalMatch ? generalMatch[0].trim() : withoutdistrict,
  };

  const fullAddress: AddressInterface = {
    raw: { value: raw, isValid: true },
    general:
      general.value === '' || !general.value ? { ...general, isValid: false } : general,
    district: district.isValid
      ? { ...district, value: district.value.replace(/^เขต/, ``).replace(/^อำเภอ/, ``) } // Shame
      : district,
    province: province.isValid
      ? { ...province, value: province.value.replace(`จังหวัด`, ``) }
      : province,
    zipCode,
  };
  // console.log(fullAddress);

  return fullAddress;
};
