import { debug } from '../utils';
import { SPREADSHEET_ID } from '../config';

import { CustomerInterface } from '../models';

export const writeRecorderNameToSpreadsheet = async ({
  allCustomers,
  recordedBy,
  isRollback = false,
}: {
  allCustomers: Partial<CustomerInterface>[];
  recordedBy: string;
  isRollback?: boolean;
}) => {
  debug(`Writing spreadsheet...`);

  const from = allCustomers[0].rowNumber ?? -1;
  const to = allCustomers[allCustomers.length - 1].rowNumber ?? -1;
  const now = new Date();
  const recordingInfo = `${recordedBy} ${now.getDate()}/${now.getMonth() + 1}/${
    now.getFullYear() + 543
  }`;
  const valueToUpdate = allCustomers.map((customer) => {
    if (isRollback) {
      return customer.isChecked ? '' : null;
    }

    return customer.isChecked ? recordingInfo : null;
  });

  if (isRollback) debug(`Rolling back spreadsheet`);
  debug(`from: `, from);
  debug(`to: `, to);
  debug(`recordingInfo: `, recordingInfo);
  debug(`valueToUpdate: `, valueToUpdate);

  const gapi = window.gapi;

  const updateResult = await gapi.client.sheets.spreadsheets.values.update(
    {
      spreadsheetId: SPREADSHEET_ID,
      range: `ฐานข้อมูลบัตรสมาชิกลูกค้า!I${from}:I${to}`,
      valueInputOption: 'RAW',
    },
    {
      majorDimension: 'COLUMNS',
      values: [valueToUpdate],
    },
  );

  debug(updateResult);

  // return customerList;
};
