import React from 'react';
import Recoil from 'recoil';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import { userRecoilState } from '../states';
import { debug } from '../utils';

const layout: React.CSSProperties = {
  display: 'flex',
  minWidth: '100vw',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export const Unauthorized = () => {
  const history = useHistory();

  const setUserState = Recoil.useSetRecoilState(userRecoilState);

  React.useEffect(() => {
    setUserState({ isCheckingLogInState: true, isLoggedIn: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    debug(`Logging in....`);

    history.replace('/');
  };

  return (
    <div style={{ ...layout }}>
      <h3>กรุณาเข้าสู่ระบบ</h3>

      <Button
        type="primary"
        style={{ backgroundColor: 'green', color: 'white' }}
        onClick={onClick}
      >
        เข้าสู่ระบบ
      </Button>
    </div>
  );
};
