import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import './index.css';
import './App.less';

import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Root } from './pages/Root';
import { Unauthorized } from './pages/Unauthorized';

import { PrivateRoute } from './pages/wrapper/PrivateRoute';

const About = () => {
  return <h2>About</h2>;
};

const NoMatch = () => {
  return <h2>Not Found</h2>;
};

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <Router>
        <div>
          {/* <GoogleApi /> */}
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <PrivateRoute exact path="/">
              <Root />
            </PrivateRoute>
            <PrivateRoute path="/about">
              <About />
            </PrivateRoute>
            <Route path="/unauthorized">
              <Unauthorized />
            </Route>
            <Route>
              <NoMatch />
            </Route>
          </Switch>
        </div>
      </Router>
    </React.StrictMode>
  </RecoilRoot>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
