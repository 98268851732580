import React from 'react';
import Recoil from 'recoil';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { Spin } from 'antd';

import { userRecoilState } from '../../states';
import { debug } from '../../utils';
import { OAUTH2_CLIENT_ID, OAUTH2_API_KEY, STAFF_NAME_DICTIONARY } from '../../config';

const getProfile = (gpi: typeof gapi) => {
  debug(`Getiing profile...`);
  const googleUser = gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile();
  debug(`Got profile for ${googleUser.getName()}!`);
  return {
    email: googleUser.getEmail(),
    name:
      STAFF_NAME_DICTIONARY[googleUser.getEmail().toLowerCase()] ?? googleUser.getName(),
  };
};

export const PrivateRoute = ({ children, path, ...rest }: RouteProps) => {
  const [userState, setUserState] = Recoil.useRecoilState(userRecoilState);

  const loginPagePath = '/unauthorized';

  React.useEffect(() => {
    if (userState.isCheckingLogInState && !userState.isLoggedIn) {
      try {
        debug(`Loading Google API...`);
        const gapi = window.gapi;
        gapi.load('client:auth2', async () => {
          debug(`Initializing...`);
          await gapi.client.init({
            apiKey: OAUTH2_API_KEY,
            clientId: OAUTH2_CLIENT_ID,
            discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
            scope: 'https://www.googleapis.com/auth/spreadsheets',
          });

          if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
            debug(`User is already authenticated.`);
            setUserState({
              isCheckingLogInState: false,
              isLoggedIn: true,
              profile: getProfile(gapi),
            });
          } else {
            debug(`User is unauthenticated.`);
            debug(`Trying to authenticate...`);
            try {
              const authenticatedGoogleUser = await gapi.auth2
                .getAuthInstance()
                .signIn();
              debug(`Authentication result: `, authenticatedGoogleUser.isSignedIn());
              if (authenticatedGoogleUser.isSignedIn()) {
                setUserState({
                  isCheckingLogInState: false,
                  isLoggedIn: true,
                  profile: getProfile(gapi),
                });
              } else {
                setUserState({
                  isCheckingLogInState: false,
                  isLoggedIn: false,
                });
              }
            } catch (error) {
              debug(`Error while trying to authenticate user!`);
              debug(error);
              setUserState({
                isCheckingLogInState: false,
                isLoggedIn: false,
              });
            }
          }
        });
      } catch (error) {
        debug(`Error while initializing Google API: `);
        debug(error);
        setUserState({ isCheckingLogInState: false, isLoggedIn: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userState.isCheckingLogInState)
    return (
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );

  return userState.isLoggedIn ? (
    <Route {...rest} render={() => children} />
  ) : (
    <Redirect
      to={{
        pathname: loginPagePath,
      }}
    />
  );
};
