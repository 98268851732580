import parsePhoneNumber from 'libphonenumber-js';

export const phoneNumberParser = (raw: string): string | undefined => {
  const phoneNumber = parsePhoneNumber(raw, 'TH');

  if (phoneNumber && phoneNumber.isValid())
    return `0${phoneNumber.nationalNumber.trim()}`;

  return undefined;
};
