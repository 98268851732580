import React from 'react';
import Recoil from 'recoil';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import './Root.less';

import { debug } from '../utils';
import { START_ROW, END_ROW } from '../config';

import { CustomerInterface } from '../models';

import { getSpreadsheetRowByRange } from '../services/get-spreadsheet-rows-by-range';

import { CustomerTable } from './components/CustomerTable';

import { userRecoilState } from '../states';

const { confirm } = Modal;

const layout: React.CSSProperties = {
  display: 'flex',
  minWidth: '100vw',
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};
const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 50, span: 50 },
};

interface FormDataInterface {
  from: number;
  to: number;
  name: string;
}

export const Root = () => {
  const [form] = Form.useForm<FormDataInterface>();

  const [isSubmit, setSubmit] = React.useState(false);

  const [inputValidation, setInputValidation] = React.useState<{
    hasFeedback: boolean;
    status: '' | 'validating' | 'success' | 'warning' | 'error';
  }>({ hasFeedback: false, status: '' });

  const [customers, setCustomers] = React.useState<Partial<CustomerInterface>[]>([]);

  const [userState, setUserState] = Recoil.useRecoilState(userRecoilState);
  const history = useHistory();

  const onFinish = async (values: FormDataInterface) => {
    try {
      debug(`Submitting form...`);
      setInputValidation({ hasFeedback: true, status: 'validating' });
      setSubmit(true);
      setCustomers(await getSpreadsheetRowByRange({ from: values.from, to: values.to }));
      setInputValidation({ hasFeedback: true, status: 'success' });
      debug(`Form submitted!`);
    } catch (_error) {
      // const exampleErrorObj = {
      //   result: {
      //     error: {
      //       code: 403,
      //       message: 'Request had insufficient authentication scopes.',
      //       status: 'PERMISSION_DENIED',
      //     },
      //   },
      //   body: '{\n  "error": {\n    "code": 403,\n    "message": "Request had insufficient authentication scopes.",\n    "status": "PERMISSION_DENIED"\n  }\n}\n',
      //   status: 403,
      //   statusText: null,
      // };

      const error: {
        result: {
          error: {
            code: number;
            message: string;
            status: string;
          };
        };
        body: string;
        status: number;
        statusText?: string;
      } = _error;
      debug(`Form submission FAILED: `);
      debug(error);

      Modal.error({
        title: <h3>พบข้อผิดพลาดระหว่างดึงข้อมูล</h3>,
        content: (
          <div>
            <div>
              <h5>Status:</h5> {error?.result?.error?.status ?? 'unknown'}
            </div>
            <div>
              <h5>Message:</h5> {error?.result?.error?.message ?? 'unknown'}
            </div>
          </div>
        ),
        okButtonProps: { danger: true },
        okText: 'ปิด',
      });

      setInputValidation({ hasFeedback: true, status: 'error' });
      setSubmit(false);
    }
  };

  const onReset = () => {
    setInputValidation({ hasFeedback: false, status: '' });
    form.resetFields();
  };

  const logout = () => {
    confirm({
      title: 'ยืนยันการออกจากระบบ',
      icon: <ExclamationCircleOutlined />,
      // content: 'Some descriptions',
      onOk() {
        debug(`Logging out...`);

        const gapi = window.gapi;
        gapi.auth2.getAuthInstance().signOut();

        setUserState({ isCheckingLogInState: true, isLoggedIn: false });

        debug(`Logged out!`);

        history.replace(`/unauthorized`);
      },
      okText: 'ยืนยัน',
      okType: 'danger',
      onCancel() {
        debug('Logout canceled');
      },
      cancelText: 'ยกเลิก',
    });
  };

  // Workaround for type safety
  if (!userState.isLoggedIn) return <div>NOPE</div>;

  return (
    <div style={{ ...layout }}>
      <Form
        {...formLayout}
        style={{ margin: 50, marginTop: 'auto' }}
        layout={customers.length > 0 ? 'inline' : 'horizontal'}
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        // initialValues={{ name: userState.profile.name }}
        initialValues={
          process.env.NODE_ENV === 'production'
            ? { name: userState.profile.name }
            : { from: START_ROW, to: END_ROW, name: userState.profile.name }
        }
      >
        <Form.Item
          name="from"
          label="แถวเริ่มต้น"
          hasFeedback={inputValidation.hasFeedback}
          validateStatus={inputValidation.status}
          rules={[{ required: true }]}
        >
          <Input type="number" disabled={isSubmit} />
        </Form.Item>
        <Form.Item
          name="to"
          label="แถวสิ้นสุด"
          hasFeedback={inputValidation.hasFeedback}
          validateStatus={inputValidation.status}
          rules={[{ required: true }]}
        >
          <Input type="number" disabled={isSubmit} />
        </Form.Item>
        <Form.Item
          name="name"
          label="ชื่อผู้กรอก"
          hasFeedback={inputValidation.hasFeedback}
          validateStatus={inputValidation.status}
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>

        {customers.length === 0 && (
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" disabled={isSubmit}>
              ดึงข้อมูลลูกค้า
            </Button>
            <Button htmlType="button" onClick={onReset} disabled={isSubmit}>
              ล้างแบบฟอร์ม
            </Button>
          </Form.Item>
        )}
      </Form>
      <br />
      {customers.length > 0 && (
        <CustomerTable
          initialCustomers={customers}
          recordedBy={form.getFieldsValue().name}
        />
      )}
      <div style={{ marginTop: 'auto' }}>
        <Button
          // type="primary"
          // disabled={isSubmit}
          style={{ backgroundColor: 'black', color: 'white', margin: 10 }}
          onClick={logout}
        >
          ออกจากระบบ
        </Button>
      </div>
    </div>
  );
};
