export const OAUTH2_CLIENT_ID = process.env.REACT_APP_OAUTH2_CLIENT_ID;
export const OAUTH2_API_KEY = process.env.REACT_APP_OAUTH2_API_KEY;

export const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID ?? '';

export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL ?? '';

export const STAFF_NAME_DICTIONARY: { [email: string]: string } = process.env
  .REACT_APP_STAFF_NAME_DICTIONARY
  ? JSON.parse(process.env.REACT_APP_STAFF_NAME_DICTIONARY)
  : {};

export const START_ROW = process.env.REACT_APP_START_ROW
  ? Number(process.env.REACT_APP_START_ROW)
  : 32292;

export const END_ROW = process.env.REACT_APP_END_ROW
  ? Number(process.env.REACT_APP_END_ROW)
  : 32305;
