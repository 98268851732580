import Recoil from 'recoil';

// import { ProfileInterface } from '../models';

type UserRecoilType =
  | { isCheckingLogInState: boolean; isLoggedIn: false }
  | {
      isCheckingLogInState: boolean;
      isLoggedIn: true;
      profile: { email: string; name: string };
    };

export const userRecoilState = Recoil.atom<UserRecoilType>({
  key: 'auth',
  default: { isCheckingLogInState: true, isLoggedIn: false },
});
