export const citizenIdParser = (
  raw: string | undefined,
): { value?: string; isValid: boolean } => {
  if (!raw || raw === '')
    return {
      value: raw,
      isValid: true,
    };

  if (raw.length !== 13)
    return {
      value: raw,
      isValid: false,
    };

  const digitSearchResult = raw.match(/\d+/g);

  if (!digitSearchResult)
    return {
      value: raw,
      isValid: false,
    };

  const numberOnlyString = digitSearchResult.join('');

  return numberOnlyString.length === 13
    ? {
        value: numberOnlyString,
        isValid: true,
      }
    : {
        value: raw,
        isValid: false,
      };
};
