import React from 'react';
import { Table, Typography, Button, Modal } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { CustomerInterface, AddressInterface } from '../../models';
import { debug } from '../../utils';
import { writeRecorderNameToSpreadsheet } from '../../services/write-recorder-name-to-spreadsheet';
import { getSpreadsheetRowByRange } from '../../services/get-spreadsheet-rows-by-range';
import { saveCustomer } from '../../api/save-customer';

import { AddressColumn } from './AddressColumn';

import './CustomerTable.css';

const { Text } = Typography;

const rowTextDisplay = (raw: string) =>
  !raw || raw === '' ? <Text type="danger">ไม่พบข้อมูล</Text> : raw;

const columns: ColumnsType<Partial<CustomerInterface>> = [
  {
    title: 'หมายเลขแถว',
    dataIndex: 'rowNumber',
    key: 'rowNumber',
  },
  {
    title: 'รหัสลูกค้า',
    dataIndex: 'id',
    key: 'id',
    render: (id: { value?: string; isValid: boolean }) => {
      // Empty id from the spreadsheet
      if (!id.isValid && id.value === '') return rowTextDisplay(id.value);

      // Case error for existed customer
      return id.isValid ? id.value : <Text type="danger">{id.value}</Text>;
    },
  },
  {
    title: 'ชื่อ',
    dataIndex: 'name',
    key: 'name',
    render: (text) => rowTextDisplay(text),
  },
  {
    title: 'เบอร์โทร',
    dataIndex: 'parsedPhoneNumber',
    key: 'parsedPhoneNumber',
    render: (text, record) =>
      text !== undefined ? text : <Text type="danger">{record.rawPhoneNumber}</Text>,
  },
  {
    title: 'เลขบัตรประชาชน',
    dataIndex: 'citizenId',
    key: 'citizenId',
    render: ({ value, isValid }: { value?: string; isValid: boolean }) =>
      isValid ? value : <Text type="danger">{value}</Text>,
  },
  {
    title: 'ที่อยู่',
    dataIndex: 'address',
    key: 'address',
    render: (address: AddressInterface, record) => <AddressColumn address={address} />,
  },
  {
    title: 'ช่องทาง',
    dataIndex: 'acquiredChannel',
    key: 'acquiredChannel',
    render: (text) => rowTextDisplay(text),
  },
  {
    title: 'ผู้บันทึก',
    dataIndex: 'recordedBy',
    key: 'recordedBy',
    render: (text) => (!text || text === '' ? text : <Text type="danger">{text}</Text>),
  },
  {
    title: 'สถานะ',
    dataIndex: 'isValid',
    key: 'isValid',
    render: (isValid: boolean, record) => {
      if (!isValid)
        return (
          <Text code type="danger">
            ข้อมูลไม่ถูกต้อง
          </Text>
        );

      return record.isChecked ? (
        <Text code strong>
          รอการบันทึก
        </Text>
      ) : (
        <Text code>ไม่บันทึก</Text>
      );
    },
  },
];

export const CustomerTable = ({
  initialCustomers,
  recordedBy,
}: {
  initialCustomers: Partial<CustomerInterface>[];
  recordedBy: string;
}) => {
  const [customers, setCustomers] = React.useState(initialCustomers);
  const [isSubmit, setSubmit] = React.useState(false);

  const onSave = async () => {
    try {
      debug(`recordedBy: ${recordedBy}`);
      debug(customers.filter((customer) => customer.isChecked));
      setSubmit(true);

      debug(`Checking current spreadsheet status again before saving`);
      const from = customers[0].rowNumber ?? -1;
      const to = customers[customers.length - 1].rowNumber ?? -1;
      const currentCustomers = await getSpreadsheetRowByRange({ from, to });

      const updatedCustomers = customers.map((customer) => {
        const current = currentCustomers.find(
          (each) => each.id?.value === customer.id?.value,
        );
        if (!current) return { ...customer, isChecked: false };

        return current.isChecked ? customer : { ...customer, isChecked: false };
      });

      debug(`Writing spreadsheet...`);
      await writeRecorderNameToSpreadsheet({
        allCustomers: updatedCustomers,
        recordedBy,
      });
      debug(`Done!`);

      debug(`Saving database...`);
      try {
        await saveCustomer({
          validCustomers: updatedCustomers
            .filter((customer) => customer.isChecked)
            .map((customer) => {
              const {
                id,
                name,
                acquiredChannel,
                parsedPhoneNumber,
                citizenId,
                address,
              } = customer;
              return {
                id: id ?? { isValid: true, value: '' },
                name: name ?? '',
                acquiredChannel: acquiredChannel ?? '',
                parsedPhoneNumber: parsedPhoneNumber ?? '',
                citizenId: citizenId ?? { isValid: true, value: '' },
                address: address!,
              };
            }),
        });
      } catch (error) {
        debug(error.message ?? error);
        debug(`Rollback spreadsheet...`);
        await writeRecorderNameToSpreadsheet({
          allCustomers: updatedCustomers,
          recordedBy,
          isRollback: true,
        });
        throw error;
      }
      debug(`Done`);

      Modal.success({
        title: 'บันทึกสำเร็จ',
        centered: true,
        okText: 'ทำรายการเพิ่มเติม',
        onOk: () => window.location.replace('/'),
      });
    } catch (_error) {
      const error: {
        result: {
          error: {
            code: number;
            message: string;
            status: string;
          };
        };
        body: string;
        status: number;
        statusText?: string;
      } = _error;
      debug(`Error while saving customers: `);
      debug(error);
      Modal.error({
        title: 'บันทึกไม่สำเร็จ',
        centered: true,
        content: (
          <div>
            <div>
              <h5>Status:</h5> {error?.result?.error?.status ?? 'unknown'}
            </div>
            <div>
              <h5>Message:</h5> {error?.result?.error?.message ?? 'unknown'}
            </div>
          </div>
        ),
        okText: 'ทำรายการอีกครั้ง',
        onOk: () => window.location.replace('/'),
        okButtonProps: { style: { background: 'red' } },
      });
    }
  };

  return (
    <>
      <Table<Partial<CustomerInterface>>
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: customers
            .filter((customer) => customer.isChecked)
            .map((customer) => customer.rowNumber ?? 0),
          onChange: (
            selectedRowKeys: React.Key[],
            selectedRows: Partial<CustomerInterface>[],
          ) => {
            setCustomers(
              customers.map((customer) =>
                selectedRowKeys.includes(customer.rowNumber ?? 0)
                  ? { ...customer, isChecked: true }
                  : { ...customer, isChecked: false },
              ),
            );
          },
          getCheckboxProps: (record: Partial<CustomerInterface>) => ({
            disabled: !record.isValid,
            // name: record.name,
          }),
        }}
        // rowClassName={(record) =>
        //   record.isValid ? 'table-row-light' : 'table-row-dark'
        // }
        columns={columns}
        dataSource={customers}
        pagination={false}
        rowKey="rowNumber"
        // className={'table-header'}
        loading={isSubmit}
      />
      <Button
        onClick={onSave}
        disabled={
          !customers.map((customer) => customer.isChecked).includes(true) || isSubmit
        }
        type="primary"
        style={{ marginTop: 16 }}
      >
        บันทึก
      </Button>
    </>
  );
};
