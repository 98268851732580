import axios from 'axios';

import { CustomerInterface } from '../models';
import { API_SERVER_URL } from '../config';

export const saveCustomer = async ({
  validCustomers,
}: {
  validCustomers: Pick<
    CustomerInterface,
    'id' | 'name' | 'acquiredChannel' | 'parsedPhoneNumber' | 'address' | 'citizenId'
  >[];
}) => {
  const formattedCustomers = validCustomers.map((customer) => {
    const { id, name, acquiredChannel, parsedPhoneNumber, citizenId, address } =
      customer;

    const { general, district, province, zipCode } = address;

    return {
      id: id.value,
      name,
      acquiredChannel,
      phoneNumber: parsedPhoneNumber,
      citizenId: citizenId.value,
      generalAddress: general.value,
      district: district.value,
      province: province.value,
      zipCode: zipCode.value,
    };
  });

  return (await axios.post<string[]>(`${API_SERVER_URL}/customer`, formattedCustomers))
    .data;
};
