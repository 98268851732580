import React from 'react';
import { Descriptions, Popover, Typography } from 'antd';

import { AddressInterface } from '../../models';

const { Link } = Typography;

export const AddressColumn = ({ address }: { address: AddressInterface }) => {
  // const isAddressValid = !Object.values(address)
  //   .map((each) => each.isValid)
  //   .includes(false);

  const AddressDescription = () => (
    <Descriptions
      title="ข้อมูลที่อยู่"
      layout="horizontal"
      bordered
      size="small"
      column={1}
    >
      <Descriptions.Item label="ที่อยู่" style={{ color: 'black' }}>
        {address.raw.value}
      </Descriptions.Item>
      <Descriptions.Item label="อำเภอ/เขต" style={{ color: 'black' }}>
        {address.district.value}
      </Descriptions.Item>
      <Descriptions.Item label="จังหวัด" style={{ color: 'black' }}>
        {address.province.value}
      </Descriptions.Item>
      <Descriptions.Item label="รหัสไปรษณีย์" style={{ color: 'black' }}>
        {address.zipCode.value}
      </Descriptions.Item>
    </Descriptions>
  );

  return (
    <Popover placement="top" content={AddressDescription} trigger="click">
      <Link href="#" style={{ color: 'black' }} underline>
        ข้อมูลที่อยู่
      </Link>
    </Popover>
  );
};
